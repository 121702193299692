import React from "react";
import Card from "react-bootstrap/Card";
import { BsController } from "react-icons/bs";
import { MdSportsMartialArts } from "react-icons/md";
import { MdOutlineTravelExplore } from "react-icons/md";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Firat Kaylan </span>
            from <span className="purple"> Winterthur, Switzerland.</span>
            <br /> I began my journey as an electrician, quickly recognizing the
            transformative potential of coding.
            <br />
            I immersed myself in coding and am currently honing my skills in a
            Coding Bootcamp.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <BsController /> Playing Games
            </li>
            <li className="about-activity">
              <MdSportsMartialArts /> Involved in high-intensity sports, with a
              focus on Mixed Martial Arts and football
            </li>
            <li className="about-activity">
              <MdOutlineTravelExplore /> Travelling
            </li>
          </ul>

          {/* Verbessertes Zitat-Layout */}
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            <p style={{ color: "rgb(155 126 172)" }}>
              "Code not just to solve problems, but to inspire solutions!"
            </p>
            <footer className="blockquote-footer">Firat</footer>
          </div>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
