import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="Pong Game"
              description="My personal Pong game is crafted using JavaScript, offering a modern twist on the classic. With sleek visuals and smooth gameplay mechanics, dive into nostalgic arcade action and challenge your reflexes in this iconic table tennis simulation."
              ghLink="https://gitlab.com/Cipyar/pong-game"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="To-do App"
              description="My Personal Todo App is crafted using React JS, providing a dynamic and intuitive interface for efficient task management. With customizable tasks and real-time updates, users can seamlessly organize their daily routines."
              ghLink="https://gitlab.com/Cipyar/todo-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Motion Backend"
              description="During our bootcamp, we collaborated as a team on an ambitious project: developing the backend for an innovative social media platform using Python, Django, Nginx, and Docker. Together, we crafted a robust and scalable backend infrastructure, ensuring the platform operates seamlessly and efficiently. Our combined expertise in these technologies not only made the project a success but also showcased our teamwork and technical prowess."
              ghLink="https://gitlab.com/Cipyar/motion-backend"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Weather App"
              description="I developed an intuitive weather app that allows users to fetch the current weather by entering a city. The project was built using modern web technologies such as React.js, HTML, CSS, and JavaScript, ensuring a responsive and user-friendly interface."
              ghLink="https://gitlab.com/Cipyar/weather-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Trivial App"
              description="Explore a dynamic quiz app, expertly crafted using React.js! Challenge yourself with a variety of topics, enjoy a sleek user experience, and track your progress as you test your knowledge. Dive in and start quizzing!"
              ghLink="https://gitlab.com/Cipyar/trivial"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
