import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiOutlineTwitter,
  AiFillInstagram,
  AiFillGitlab,
} from "react-icons/ai";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ALLOW ME TO <span className="purple"> PRESENT </span> MYSELF
            </h1>
            <p className="home-about-body">
              I've recently discovered my passion for programming, and every day
              is a new learning opportunity for me. 🌟
              <br />
              <br />
              While I'm still exploring, I have hands-on experience with
              <i>
                <b className="purple">
                  {" "}
                  Javascript, HTML, CSS, React.js and Python.{" "}
                </b>
              </i>
              <br />
              <br />
              My interests lie in creating impactful
              <i>
                <b className="purple"> applications and websites </b>
              </i>
              that genuinely add value to the user's experience.
              <br />
              <br />
              Given the chance, I enjoy building products using
              <b className="purple"> Node.js</b>,
              <b className="purple"> Python</b>, and
              <i>
                <b className="purple"> contemporary frameworks </b>
              </i>
              , especially
              <i>
                <b className="purple"> React.js</b>
              </i>{" "}
              and
              <i>
                <b className="purple"> Django.</b>
              </i>
            </p>
          </Col>

          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://gitlab.com/Cipyar"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGitlab />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/xCipyar"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              {/* <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/soumyajit4419/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li> */}
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/fxdv.46/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
